<template>
  <section class="invoice-add-wrapper">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="invoiceData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list'}"
        >
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert>

    <b-row
      v-if="invoiceData.systemSettings"
      class="invoice-add"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div class="d-flex">
                  <div class="logo-wrapper">
                    <b-img
                      :src="require('@/assets/images/logo/tm-partial-logo-2.png')"
                      alt="logo"
                    />
                    <h3 class="text-primary invoice-logo ml-0">
                      {{ appName }}
                    </h3>
                  </div>
                </div>

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title  mr-2">
                      {{ invoiceText }}
                    </h4>
                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="invoice-data-id"
                        v-model="invoiceData.invoiceNo"
                        disabled
                      />
                    </b-input-group>
                  </div>
                  <div class="d-flex align-items-center mb-1 justify-content-md-end">
                    <span class="title">
                      Date Issued:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.dateIssued"
                      class="form-control invoice-edit-input"
                    />
                  </div>
                  <div class="d-flex align-items-center justify-content-md-end">
                    <span class="title">
                      Due Date:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.dueDate"
                      class="form-control invoice-edit-input"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0"
            >
              <div class="invoice-spacing">

                <!-- Col: Invoice From -->
                <div
                  class="p-0"
                >
                  <h6 class="mb-2">
                    From:
                  </h6>
                  <h6 class="mb-25 font-weight-bolder">
                    {{ invoiceData.systemSettings.company_name }}
                  </h6>
                  <p class="card-text mb-25">
                    {{ invoiceData.systemSettings.company_address1 }}
                  </p>
                  <p class="card-text mb-25">
                    {{ invoiceData.systemSettings.company_address2 }}
                  </p>
                  <p class="card-text mb-25">
                    {{ invoiceData.systemSettings.vatno }}
                  </p>
                </div>

                <!-- Col: To -->
                <div
                  v-if="isAnulat===0"
                  class="p-0"
                >
                  <h6 class="mb-2">
                    To:
                  </h6>
                  <h6 class="mb-25 font-weight-bolder">
                    <!-- {{ invoiceData.client }} -->
                    Client/Company:<b-form-input
                      :value="invoiceData.client"
                      @change="editClientFn($event)"
                    /></h6>
                  <p class="card-text mb-25 invoice-to-address">
                    <span>
                      Address: <b-form-input
                        :value="invoiceData.address1"
                        @change="editAddressFn($event)"
                      />
                      City:<b-form-input
                        :value="invoiceData.city"
                        @change="editCityFn($event)"
                      />
                    </span>
                    <span style="display:flex; align-items:center; gap:5px;margin-top: 5px;">
                      Postcode:<b-form-input
                        :value="invoiceData.postcode"
                        @change="editPostcodeFn($event)"
                      />
                      Region:<b-form-input
                        :value="invoiceData.region"
                        @change="editRegionFn($event)"
                      />
                    </span>
                    Country :  <b-form-select
                      v-model="invoiceData.country"
                      style="text-align: center; height: 40px; display: flex; align-items: center; justify-content: center; font-weight: bolder; gap:4px;"
                      @change="editCountryFn($event)"
                    >
                      <option
                        v-for="option in editCountry"
                        :key="option.iso"
                        :value="option.iso"
                      >
                        <p style="padding:4px;margin:10px;color:black;">
                          {{ option.name }}
                        </p>
                      </option>
                    </b-form-select>
                  </p>
                  <p class="card-text mb-25">
                    Tax-ID/VAT: <b-form-input
                      :value="invoiceData.tax"
                      @change="editVatFn($event)"
                    />
                  </p>
                </div>
              </div>
            </b-card-body>

            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
                :style="{height: trHeight}"
              >
                <b-row
                  v-for="(item, index) in invoiceData.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >

                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">

                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="5"
                        >
                          Item
                        </b-col>
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          Qty
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Unit Price
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Item Price
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="item border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="5"
                        >
                          <label class="d-inline d-lg-none">Item</label>
                          <v-select
                            v-if="item.type === 'package'"
                            v-model="item.itemTitle"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="packages"
                            label="text"
                            :clearable="false"
                            class="item-selector-title"
                            placeholder="Select Package"
                            @input="updateTotal(index)"
                          />
                          <b-form-input
                            v-else
                            v-model="item.itemTitle"
                            placeholder="Enter Custom Name"
                            @blur="updateTotal(index)"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          <label class="d-inline d-lg-none">Qty</label>
                          <!-- <b-form-spinbutton
                            id="sb-default"
                            v-model="item.qty"
                            placeholder="--"
                            @change="updateTotal(index)"
                          /> -->
                          <b-form-input
                            v-model="item.qty"
                            @change="updateTotal(index)"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                          class="item-row"
                        >
                          <label class="d-inline d-lg-none">Unit Price</label>
                          <p
                            v-if="item.type === 'package'"
                            class="mb-0"
                          >
                            € {{ parseFloat(item.unitPrice).toFixed(2) }}
                          </p>
                          <b-input-group
                            v-else
                            prepend="€"
                            class="input-group-merge"
                          >
                            <b-form-input
                              v-model="item.unitPrice"
                              @blur="updateTotal(index)"
                            />
                          </b-input-group>
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                          class="item-row"
                        >
                          <label class="d-inline d-lg-none">Item Price</label>
                          <p
                            v-if="item.type === 'package'"
                            class="mb-0"
                          >
                            € {{ parseFloat(item.itemPrice).toFixed(2) }}
                          </p>
                          <p
                            v-else
                            class="mb-0"
                          >
                            € {{ parseFloat(item.itemPrice).toFixed(2) }}
                          </p>
                        </b-col>
                      </b-row>
                      <div
                        v-if="item.type !== 1"
                        class="item-remove border-left py-50 px-25"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>

              <div v-if="invoiceData.startDate">
                <b-row>
                  <b-col>
                    <div class="period mb-3 mt-1">
                      <h5 class="mr-1">
                        Period:
                      </h5>
                      <div class="date">
                        <flat-pickr
                          v-model="invoiceData.startDate"
                          class="form-control invoice-edit-input"
                        />
                        <h2 class="mx-1 text-center">
                          -
                        </h2>
                        <flat-pickr
                          v-model="invoiceData.endDate"
                          class="form-control invoice-edit-input"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                class="mr-1"
                @click="addNewItemInItemForm('custom')"
              >
                Add Custom
              </b-button>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-3">
              <b-row>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Subtotal:
                      </p>
                      <p class="invoice-total-amount">
                        € {{ parseFloat(invoiceData.subTotal).toFixed(2) }}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        VAT ({{ invoiceData.vatPercentage }}%):
                      </p>
                      <p class="invoice-total-amount">
                        € {{ parseFloat(invoiceData.vat).toFixed(2) }}
                      </p>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        € {{ parseFloat(invoiceData.totalToPay).toFixed(2) }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body
              v-if="invoiceData.vatPercentage === 0"
              class="invoice-padding text-center pt-0"
            >
              <p v-if="invoiceData.version === 1">
                Exempt by virtue of Art 7 of the Twelfth Schedule to the Value Added Tax Act - Chapter 406
              </p>
              <p v-else>
                Customer to account for any VAT arising on this supply in accordance with Article 196 Council Directive 2016/112/EC
              </p>
              <p
                v-if="payment && invoiceData.invoice_status.toLowerCase().trim()==='paid'"
                class="paid-style mt-1"
              >
                <span v-if="payment.paidWith === 'creditCard'">
                  Paid by Credit Card on {{ payment.payment_date }}<br>Transaction Reference: {{ payment.payment + 'I' + payment.invoice }}
                </span>
                <span v-else-if="payment.paidWith === 'credit_card'">
                  Paid by Credit Card on {{ payment.payment_date }}<br>Transaction Reference: {{ payment.ipc_trnref ? payment.ipc_trnref : invoiceDetails.invoice_number_v2 }}
                </span>
                <span v-else-if="payment.paidWith === 'payPal'">
                  Paid by PayPal on {{ payment.payment_date }}<br>Transaction Reference: {{ payment.ipc_trnref }}
                </span>
                <span v-else-if="payment.paidWith === 'wire'">
                  Paid by Wire Transfer on {{ payment.payment_date }}
                </span>
              </p>
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >

        <!-- Action Buttons -->
        <b-card>
          <div
            class="mt-75"
            block
          >
            <p><b>Invoice Status:</b></p>
            <b-form-select
              v-model="invoiceStatus"
              :options="invoiceStatusOption"
              @change="updateStatus"
            />
          </div>
          <br>

          <!-- Button: Send Invoice -->
          <b-button
            variant="primary"
            class="mb-75"
            block
            @click="updateInvoice"
          >
            Save
          </b-button>
          <!-- <b-button
            variant="primary"
            class="mb-75 btn btn-block"
            blocks
            @click="editBillingProfile"
          >
            Edit Billing Profile
          </b-button> -->
          <b-button
            variant="outline-secondary"
            class="mb-75"
            block
            @click="cancelUpdate"
          >
            Cancel Edit
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BAlert,
  BLink,
  VBToggle,
  BImg,
  BFormSelect,
  // BFormSpinbutton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { $themeConfig } from '@themeConfig'
import axios from '@/libs/axios'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    // BFormSpinbutton,
    BAlert,
    BImg,
    BLink,
    flatPickr,
    vSelect,
    BFormSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      recordID: 0,
      invoiceStatus: 0,
      invoiceStatusOption: [
        { value: 1, text: 'Paid' },
        { value: 0, text: 'Not Paid' },
      ],
      appName: null,
      periodBoolean: false,
      payment: null,
      invoiceText: '',
      invoiceData: {
        dateIssued: '',
        dueDate: '',
        invoiceTo: null,
        client: '',
        address: '',
        tax: '',
        country: '',
        subTotal: 0,
        totalToPay: 0,
        vat: 0,
        city: '',
        region: '',
        address1: '',
        vatPercentage: 0,
        bankDetails: '',
        existingClients: false,
        systemSettings: null,
        invoiceNo: 0,
        currency: '',
        postcode: '',
        discountValue: 0,
        invoice_status: '',
        startDate: null,
        endDate: null,
        items: [],
        creditNote: '',
      },
      editClient: '',
      editAddress: '',
      editVat: '',
      // editCountry: '',
      // make editCountry a select with country list
      editCountry: '',
      selectCountry: '',
      editCity: '',
      editRegion: '',
      editPostcode: '',
      editBilling: false,
      isAnulat: 0,
    }
  },

  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    'invoiceData.items': function () {
      this.initTrHeight()
    },
  },
  mounted() {
    this.initTrHeight()
    this.fetchCountries()
  },
  created() {
    this.appName = $themeConfig.app.appName
    this.getInvoiceByID()
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    fetchCountries() {
      axios.get('api/countries').then(response => {
        // find item with ISO 'GS'
        const index = response.data.findIndex(x => x.iso === 'GS')
        // Rename it sandwith islands
        // eslint-disable-next-line no-param-reassign
        response.data[index].name = 'SG Sandwich Islands'
        this.editCountry = response.data
      })
    },
    editCityFn(event) {
      this.editCity = event
      this.editBilling = true
    },
    editPostcodeFn(event) {
      this.editPostcode = event
      this.editBilling = true
    },
    editRegionFn(event) {
      this.editRegion = event
      this.editBilling = true
    },
    editClientFn(event) {
      this.editClient = event
      this.editBilling = true
    },
    editAddressFn(event) {
      this.editAddress = event
      this.editBilling = true
    },
    editCountryFn(e) {
      this.selectCountry = e
      this.editBilling = true
    },
    editVatFn(event) {
      this.editVat = event
      this.editBilling = true
    },
    editBillingProfile() {
      if (this.editBilling === true) {
        axios
          .post('/api/users/update_billing_profile', {
            invoice_id: this.invoiceData.creditNote,
            bill_name: this.editClient,
            bill_country: this.selectCountry,
            bill_address1: this.editAddress,
            bill_vatno: this.editVat,
            bill_region: this.editRegion,
            bill_city: this.editCity,
            bill_postcode: this.editPostcode,
          })
      }
      this.editBilling = false
    },
    addNewItemInItemForm(type) {
      this.$refs.form.style.overflow = 'hidden'
      this.invoiceData.items.push({
        itemTitle: null, qty: 0, unitPrice: 0, itemPrice: 0, type,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
      if (type === 'package') this.packageBoolean = true
    },
    insertPeriod() {
      const today = new Date()
      this.invoiceData.startDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      this.invoiceData.endDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`

      this.periodBoolean = true
    },
    removePeriod() {
      this.periodBoolean = false
      this.invoiceData.startDate = null
      this.invoiceData.endDate = null
    },
    isNumber(str) {
      const pattern = /^-?\d+\.?\d*$/
      return pattern.test(str)
    },
    getInvoiceByID() {
      this.$store.dispatch('invoice/getInvoiceByID', this.$route.params.id)
        .then(res => {
          this.isAnulat = Number(res.is_anulat)
          this.recordID = this.$route.params.id
          this.invoiceType = res.invoiceType
          this.invoiceDetails = res.invoice
          this.invoiceData.systemSettings = res.systemSettings

          if (res.invoiceType === 'invoice') {
            this.payment = res.payment
            this.invoiceData.invoice_status = res.invoice.invoice_status

            this.invoiceData.creditNote = res.invoice.credit_note

            if (res.invoice.is_credit_note === 0) {
              this.invoiceText = 'Invoice'
              this.invoiceData.invoiceNo = ''
              if (res.invoice.version === 1) this.invoiceData.invoiceNo += res.invoice.invoice
              else this.invoiceData.invoiceNo += `TM${res.invoice.invoice_number_v2.toString().padStart(4, '0')}`
            } else {
              this.invoiceData.invoiceNo = res.invoice.credit_note
              this.invoiceText = 'Pro Forma Invoice'
            }

            this.invoiceData.address = res.billing.bill_address1
            if (res.billing.bill_address2) this.invoiceData.address += `${res.billing.bill_address1}, ${res.billing.bill_address2}`
            this.invoiceData.address += ` ${res.billing.bill_city}, ${res.billing.bill_postcode} ${res.billing.bill_region}`
            this.invoiceData.city = res.billing.bill_city
            this.invoiceData.region = res.billing.bill_region
            this.invoiceData.address1 = res.billing.bill_address1
            this.invoiceData.postcode = res.billing.bill_postcode

            this.invoiceData.dateIssued = res.invoice.issue_date
            this.invoiceData.dueDate = res.invoice.due_date
            this.invoiceData.client = res.billing.bill_name
            this.invoiceData.tax = res.billing.bill_vatno
            this.invoiceData.country = res.billing.bill_country
            res.items.forEach(item => {
              this.invoiceData.items.push({
                id: item.id, type: item.type, itemTitle: item.item, qty: parseFloat(item.qty), unitPrice: `${parseFloat(item.unit_price).toFixed(2)}`, itemPrice: `${parseFloat(item.item_price).toFixed(2)}`,
              })
            })

            this.invoiceData.startDate = res.invoice.start_date
            this.invoiceData.endDate = res.invoice.end_date
            this.invoiceData.currency = res.invoice.currency
            this.invoiceData.subTotal = res.invoice.subtotal_value
            this.invoiceData.totalToPay = res.invoice.invoice_value
            this.invoiceData.vat = res.invoice.vat_value
            this.invoiceData.vatPercentage = res.invoice.vat
            this.invoiceData.discountValue = res.invoice.discount_value
            this.invoiceData.invoiceTo = res.invoice.uid
          }

          if (this.invoiceData.invoice_status.toLowerCase().trim() === 'paid') {
            this.invoiceStatus = 1
          }

          this.updateTotal(this.invoiceData.items.length - 1)
        })
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0)
      })
    },
    // removeItem(index) {
    //   if (this.invoiceData.items[index].type === 'package') this.packageBoolean = false

    //   const itemTemp = this.invoiceData.items[index]
    //   this.invoiceData.items.splice(index, 1)
    //   this.trTrimHeight(this.$refs.row[0].offsetHeight)
    //   this.invoiceData.subTotal = 0

    //   const isQtyNum = this.isNumber(itemTemp.qty)
    //   if (isQtyNum === false) {
    //     this.notify({
    //       text: 'Please enter a valid quantity! It must contain a number not a character. Changing the value of quantity to default value of 1.',
    //       variant: 'danger',
    //     })
    //     itemTemp.qty = 1
    //   }

    //   if (index != null && itemTemp.itemTitle !== null && itemTemp.qty !== 0) {
    //     this.invoiceData.items[index].itemPrice = itemTemp.qty * itemTemp.unitPrice
    //   }
    //   this.invoiceData.items.forEach(item => {
    //     this.invoiceData.subTotal += parseFloat(item.itemPrice)
    //   })
    //   this.invoiceData.vat = this.invoiceData.subTotal * (this.invoiceData.vatPercentage / 100)
    //   this.invoiceData.totalToPay = this.invoiceData.subTotal + this.invoiceData.vat
    // },
    updateInvoice() {
      // const tempItem = this.invoiceData.items.find(obj => obj.type === 'package')

      const customData = []
      this.invoiceData.items.forEach(item => {
        if (item.type === 'custom') customData.push(item)
      })

      // eslint-disable-next-line no-unused-vars
      const payload = {
        id: this.$route.params.id,
        issue_date: this.invoiceData.dateIssued,
        due_date: this.invoiceData.dueDate,
        start_date: this.invoiceData.startDate, // Period start
        end_date: this.invoiceData.endDate, // Period  end
        vat: this.invoiceData.vatPercentage, // vat %
        subtotal_value: this.invoiceData.subTotal, // subtotal
        invoice_value: this.invoiceData.totalToPay, // total
        items: this.invoiceData.items,
        status: this.invoiceStatus,
      }

      this.$store.dispatch('invoice/updateInvoice', payload)
        .then(res => {
          if (res) {
            this.notify({
              text: 'Invoice updated!',
              variant: 'success',
            })
            this.$router.push(`/invoices/${this.$route.params.id}`)
          }
        })

      this.editBillingProfile()
    },

    updateTotal(index) {
      const itemTemp = this.invoiceData.items[index]
      this.invoiceData.subTotal = 0

      // If the unit price is not a number do not update the item price and notify the user
      const isUnitPriceNum = this.isNumber(itemTemp.unitPrice)
      if (isUnitPriceNum === false) {
        this.notify({
          text: 'Please enter a valid unit price! It must contain a number not a character.',
          variant: 'danger',
        })
      }

      const isQtyNum = this.isNumber(itemTemp.qty)
      if (isQtyNum === false) {
        this.notify({
          text: 'Please enter a valid quantity! It must contain a number not a character. Changing the value of quantity to default value of 1.',
          variant: 'danger',
        })
        itemTemp.qty = 1
      }

      if (index != null && itemTemp.itemTitle !== null && itemTemp.qty !== 0) {
        this.invoiceData.items[index].itemPrice = itemTemp.qty * itemTemp.unitPrice
      }
      this.invoiceData.items.forEach(item => {
        this.invoiceData.subTotal += parseFloat(item.itemPrice)
      })
      this.invoiceData.vat = this.invoiceData.subTotal * (this.invoiceData.vatPercentage / 100)
      this.invoiceData.totalToPay = this.invoiceData.subTotal + this.invoiceData.vat
    },
    // New Remove Item function
    removeItem(index) {
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
      this.invoiceData.subTotal = 0
      this.invoiceData.items.forEach(item => {
        this.invoiceData.subTotal += parseFloat(item.itemPrice)
      })
      this.invoiceData.vat = this.invoiceData.subTotal * (this.invoiceData.vatPercentage / 100)
      this.invoiceData.totalToPay = this.invoiceData.subTotal + this.invoiceData.vat
    },
    cancelUpdate() {
      const { id } = this.$route.params
      this.$router.push(`/invoices/${id}`)
    },
    updateStatus() {
      let statusText = 'paid'
      if (this.invoiceStatus === 0) statusText = 'not-paid'
      const payload = {
        status: statusText,
        id: this.recordID,
      }
      this.$store.dispatch('invoice/updateInvoiceStatus', payload)
        .then(res => {
          if (res) {
            this.notify({
              text: 'Invoice status will be  updated after saving!',
              variant: 'success',
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.notify({
            text: 'Invoice update status failed!',
            variant: 'danger',
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.invoice-to-address {
  max-width: 20rem;
  line-height: 24px;
}

.form-item-section {
  background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}
.paid-style {
  color: #189550;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
